import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const EyesGratioCircle = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>切らない黄金比クマ取り</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>切らない黄金比クマ取りとは</h2>
            <p>
                ひとりひとりに最適な黄金比に合わせ左右対称になるようにデザインし、施術を行います。<br />
                また針を極力細くし、麻酔液も複数の論文のデータをもとに調整することで、痛みに最大限配慮しています。<br />
                鎮静麻酔を使用することで無痛で施術をすることも可能です。<br />
                また10,000件以上の施術経験と100を超える論文をデータをもとにした、T CLINIC独自のクマ取りにより腫れや内出血を最小限にし、従来よりもモチの良い自然で美しい目元にすることができます。<br />
                切らない黄金比クマ取りは皮膚に一切の傷をつけず、隔膜前ルートで眼窩脂肪を摘出することで、取り残しや凹みすぎず目の下のクマをしっかり改善します。<br />
                同時に涙袋の形成や目の開きも改善させる手術方法です。<br />
                平均1〜4日が腫れのピーク、5〜7日でほとんど腫れは引いてきます。<br />
                内出血が出た場合は1〜2週間で改善しますので、生じた際には濃いメイクでカバーをおすすめします。<br />
                1ヶ月で腫れは完全になくなりますが、2〜3ヶ月でコラーゲンが生成されるのでより自然で仕上がりが良くなると言われております。<br />
                ただし、ダウンタイムには個人差がありますので、経過には多少の前後がございます。<br />
                腫れや内出血を最小限にする内服薬・外用薬・点滴の用意もございます。<br />
                T CLINICではひとりひとりへのカウンセリング・施術前のシミュレーション・施術を時間をかけて丁寧に行っております。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-eyes-gratiocircle-img.png' alt='about-area-eyes-gratiocircle-img' className={styles.pc_img}/>
                <img src='/images/about-area-eyes-gratiocircle-sp-img.png' alt='about-area-eyes-gratiocircle-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />目の下のクマが気になる方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />涙袋が欲しい方（他院修正）
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想の目もとをお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず目もとの状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>クマの状態に合わせて施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は30-60分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                        施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        <Element className={styles.case_area} name='case'>
            <h2>切らない黄金比クマ取りの症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/eyes-gratiocircle-img.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/eyes-gratiocircle-img2.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/eyes-gratiocircle-img3.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/eyes-gratiocircle-img4.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element>

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>切らない黄金比クマ取り</div>
                    <div>¥162,800〜¥382,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>切らない黄金比クマ取り</div>
                    <div>1部位 ¥162,800<span>モニター価格 ¥150,000</span></div>
                </div>
                <div className={styles.price_list}>
                    <div>1部位追加</div>
                    <div>¥110,000</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：1ヶ月〜3ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・目の下のたるみが目立つことがある</li>
                        <li>・腫れや内出血</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default EyesGratioCircle