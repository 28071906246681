import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const NoseImplantation = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>黄金比鼻先移植術/鼻柱下降術</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>鼻先移植術とは</h2>
            <p>
                ポリカプロラクトンの吸収糸もしくは人工真皮を鼻先にいれて高さを出したり向きを調整します。<br />
                隙間に入り込んだ自家組織が定着し、半永久的な効果が期待できます。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-nose-implantation-img.png' alt='about-area-nose-implantation-img' className={styles.pc_img}/>
                <img src='/images/about-area-nose-implantation-sp-img.png' alt='about-area-nose-implantation-sp-img' className={styles.sp_img}/>
            </div>
            <h2>鼻柱下降術とは</h2>
            <p>
                ポリカプロラクトンの吸収糸もしくは人工真皮を鼻柱にいれて鼻柱の後退を黄金比に合わせます。<br />
                隙間に入り込んだ自家組織が定着し、半永久的な効果が期待できます。
            </p>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />鼻先を高くしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />鼻先の丸みが気になる方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />鼻先の向きを変えたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />他の部位を傷つけずに鼻先に高さを出したい方。
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想の鼻先をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず鼻の状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望の鼻先に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は60-120分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>鼻先移植術の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>黄金比鼻先移植術/鼻柱下降術</div>
                    <div>¥165,000〜¥547,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>黄金比鼻先移植術/鼻柱下降術(吸収糸1本)</div>
                    <div>¥165,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>黄金比鼻先移植術/鼻柱下降術(真皮移植)</div>
                    <div>¥547,800</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・黄金比鼻先移植術治療期間：1ヶ月<br />
                        ・鼻柱下降術治療期間：1週間<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・腫れ</li>
                        <li>・内出血</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default NoseImplantation