import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const InjectionHyaluronic = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>ヒアルロン酸注射</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>ヒアルロン酸注射とは</h2>
            <p>
                黄金比に合わせて左右対称になるようにヒアルロン酸を注射します。<br />
                <br />
                おでこ：おでこが丸くなりシワがよりにくくなる、横顔が綺麗になる、目の開きが良くなる、重心が上になり小顔効果がある。<br />
                こめかみ：こめかみの凹みが改善し輪郭が綺麗になる、リフトアップ効果<br />
                鼻筋：鼻筋のバランスが良くなり全体のバランスが整う、目と目の距離が近づいたように見える、蒙古襞の張り感の改善、横顔の改善、忘れ鼻に近く<br />
                鼻中隔：鼻先にはヒアルロン酸は入れないが鼻先がつんと高さが出る、鼻柱の後退が改善される、忘れ鼻に近く<br />
                目の上：目の上の凹みの改善<br />
                涙袋：涙袋がぷっくりする<br />
                ほうれい線・貴族：ほうれい線の改善、小鼻が小さくなる<br />
                マリオネット線：マリオネット線の改善<br />
                頬・ゴルゴ線：頬のボリュームアップで疲れ顔の改善、中顔面が短く見える、ゴルゴ線の改善<br />
                頬コケ：コケが改善し輪郭が美しくなる、男性的な印象の改善<br />
                顎：顎がつんと出ることで正面から見た輪郭の改善、Eラインの改善、フェイスラインがすっきりする
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-injection-hyaluronic-img.png' alt='about-area-injection-hyaluronic-img' className={styles.pc_img}/>
                <img src='/images/about-area-injection-hyaluronic-sp-img.png' alt='about-area-injection-hyaluronic-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />左右差を調整したい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />バレずに顔を整えたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />お手軽に処置をしたい方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は5-10分程度になります。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>ヒアルロン酸注射の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>ヒアルロン酸注射</div>
                    <div>¥52,800〜¥107,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>Neuramis Lidocaine 1cc</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>Neuramis DEEP 1cc</div>
                    <div>¥58,300</div>
                </div>
                <div className={styles.price_list}>
                    <div>Neuramis Volume 1cc</div>
                    <div>¥63,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ジュビダームVOLBELLA XC 1cc</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ジュビダームVOLIFT XC 1cc</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ジュビダームVOLUMA XC 1cc</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ジュビダームVOLUX XC 1cc</div>
                    <div>¥107,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>施術料(笑気麻酔込み)</div>
                    <div>¥22,000</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：週1回の投与を5週続け、その後は効果に応じて2~4週間に1回<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・腫れ</li>
                        <li>・注入部分の突っ張り</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default InjectionHyaluronic