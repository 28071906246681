import React from 'react'
import styles from '../styles/Footer.module.css';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

const Footer = () => {
  return (
    <>
        <section className={styles.map_area}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3184718071143!2d139.69921237629188!3d35.693779829283656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188df229e9d5eb%3A0x94f52551d55ea750!2zVC1DTElOSUMg5paw5a6_6Zmi!5e0!3m2!1sja!2sjp!4v1685426991895!5m2!1sja!2sjp"  className={styles.map} title='map'></iframe>
        </section>
        <footer>
            <div className={styles.footer_left_content}>
                <span>美容外科/美容皮膚科 JR山手線新宿駅から徒歩3分</span>
                <Link className={styles.logo_area} to="/">
                    <img src='/images/logo.png' className={styles.logo} alt='img' />
                    <div>
                        T CLINIC<br />
                        美容外科/美容皮膚科
                    </div>    
                </Link>
                <ul className={styles.footer_sns}>
                    <li>
                        <Link to="https://www.instagram.com/tclinic_sagara">
                            <img src='/images/insta-icon.png' className={styles.sns_icons} alt='img' />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://twitter.com/tclinic_sagara">
                            <img src='/images/x-icon.png' className={styles.sns_icons} alt='img' />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://www.tiktok.com/@tclinic_official">
                            <img src='/images/tiktok-icon.png' className={styles.sns_icons} alt='img' />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://page.line.me/862rmedt">
                            <img src='/images/line-icon.png' className={styles.sns_icons} alt='img' />
                        </Link>
                    </li>
                </ul>
                <div className={styles.address}>
                    〒160-0021<br />
                    東京都新宿区歌舞伎町1-16-5ドン・キホーテ新宿ビル7階
                </div>
                <Button 
                    variant="contained" 
                    className={styles.footer_map_btn}
                    href="https://www.google.com/maps/place/T-CLINIC+%E6%96%B0%E5%AE%BF%E9%99%A2/@35.6937755,139.7017873,17z/data=!3m2!4b1!5s0x60188cd9dbc2163f:0x54a22900434443d1!4m6!3m5!1s0x60188df229e9d5eb:0x94f52551d55ea750!8m2!3d35.6937755!4d139.7017873!16s%2Fg%2F11t7zcz795?entry=ttu"
                    style={{ textTransform: 'none' }}
                    >
                    <img src='/images/pin-icon.png' className={styles.pin_icon} alt='img' />
                    Google Map
                </Button>
            </div>
            <div className={styles.footer_right_content}>
                <div className={styles.footer_nav}>
                    <ul>
                        <li>
                            <Link to="/">ホーム</Link>
                        </li>
                        <li>
                            <Link to="/access">アクセス</Link>
                        </li>
                        <li>
                            <Link to="/about">当院について</Link>
                        </li>

                        <li>
                            <Link to="/contact">お問い合わせ</Link>
                        </li>
                        <li>
                            <Link to="/menu">施術・料金一覧</Link>
                        </li>
                        {/* <li>
                            <Link to="/">プライバシーポリシー</Link>
                        </li> */}
                        <li>
                            <Link to="/case">症例写真</Link>
                        </li>
                        <li>
                            <Link to="/doctor">ドクター紹介</Link>
                        </li>
                    </ul>
                </div>
                <div className={styles.footer_cta}>
                    <Button variant="contained" className={styles.line_button}>
                        <Link to="https://page.line.me/862rmedt">
                            <img src='/images/line-icon.png' className={styles.line_button_icon} alt='img' />LINE相談・予約
                        </Link>
                    </Button>
                    <Button variant="contained" className={styles.web_button}>
                        <Link to="https://connect.kireipass.jp/clinics/t-clinic-shinjuku/reservations/new?large_category_id=6185&menu_id=19150">WEB予約</Link>
                    </Button>
                    <Button variant="contained" className={styles.tel_button}>
                        <Link to="tel:03-6228-0830">
                            <div>
                                <span className={styles.time}>受付時間: 10:00~19:00(不定休)</span><br />
                                TEL <span className={styles.num}>03-6228-0830</span>
                            </div>
                        </Link>
                    </Button>
                    <small>
                        &copy;2023 T CLINIC all rights reserved.
                    </small>
                </div>
            </div>
        </footer>
    </>
    
  )
}

export default Footer