import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const InjectionBotox = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>ボトックス注射</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>ボトックス注射とは</h2>
            <p>
                ボトックスを入れることで表情筋や汗腺の働きを抑えます。<br />
                <br />
                おでこ：前頭筋の動きを抑えることで、おでこのシワの改善、目を開ける際におでこをあげてしまう癖の改善。<br />
                眉上：眉丘筋の動きを抑えることで、眉毛をあげてしまう癖の改善。<br />
                眉間：皺眉筋と鼻根筋の動きを抑えることで、眉間にシワを寄せてしまう癖の改善。<br />
                バニー：鼻根筋の動きを抑えることで、バニーラインの改善。<br />
                小鼻：鼻孔開大筋の動きを抑えることで、小鼻の広がりを抑える。<br />
                目尻：目尻の眼輪筋の動きを抑えることで、目尻のシワの改善。<br />
                目の下：目の下の眼輪筋の動きを抑えることで、目の下のシワの改善。<br />
                タレ目：目尻付近の眼輪筋の動きを抑えることで、タレ目にする。<br />
                ガミースマイル：上唇挙筋の動きを抑えることで、ガミースマイルの改善。<br />
                口角：口角下制筋の動きを抑えることで、口角を上げる。<br />
                エラ：咬筋の動きを抑えることで、歯軋りの改善や小顔効果。<br />
                広頸筋：広頸筋の動きを抑えることで、首のスジの改善やリフトアップする。<br />
                他には目頭、口上、口下、人中短縮、花粉症、側頭筋なども施術可能です。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-injection-botox-img.png' alt='about-area-injection-botox-img' className={styles.pc_img}/>
                <img src='/images/about-area-injection-botox-sp-img.png' alt='about-area-injection-botox-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />小皺が出る方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />20代後半以降の方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />筋肉が張り出している方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は5-15分程度になります。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

                {/* <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div> */}

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>ボトックス注射の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>ボトックス注射</div>
                    <div>¥16,280〜¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックス1部位</div>
                    <div>¥16,280</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックスタレ目/口角/ガミー/花粉症</div>
                    <div>¥27,280</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックスエラ通常</div>
                    <div>¥41,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックスエラ強力</div>
                    <div>¥63,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックス肩通常</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックス肩強力</div>
                    <div>¥74,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックス多汗症通常</div>
                    <div>¥74,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガン1部位</div>
                    <div>¥21,780</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガンタレ目/口角/ガミー/花粉症</div>
                    <div>¥32,780</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガンエラ通常</div>
                    <div>¥63,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガンエラ強力</div>
                    <div>¥85,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガン肩通常</div>
                    <div>¥74,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガン肩強力</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガン多汗症通常</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>施術料(笑気麻酔込み)</div>
                    <div>¥22,000</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間(メンテナンス)：3ヶ月〜4ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・咀嚼の困難感</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default InjectionBotox