import React from 'react'
import styles from '../styles/Doctor.module.css';
import { Breadcrumbs } from '../components/Breadcrumb';

const Doctor = () => {
  return (
    <>
      <section className={styles.fv_area}>
          <h2>
              Doctor
              <span>ドクター紹介</span>
          </h2>
          <p>
            患者様を幸せにし、<br />
            満足度日本一のクリニックを目指します。
          </p>
      </section>
      <Breadcrumbs />

      <section className={styles.interview_area}>
        <h2>
          Interview
          <span>院長先生のインタビュー</span>
        </h2>
        <div className={styles.prof_contents}>
          <div className={styles.prof_content}>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/nOxSGoeFKmw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className={styles.video}></iframe>
            <div className={styles.title}>
              <div>
                <span className={styles.job_title}>院長</span>
                <div className={styles.name}>相良卓哉</div>
                <span className={styles.en_name}>Takuya Sagara</span>
              </div>
              <div>
                <img src='/images/prof-img.png' className={styles.prof_img} alt='img' />
              </div>
            </div>
            <p>
              はじめまして。T CLINIC統括院長の相良卓哉です。<br />
              私自身見た目でいじめられ自信を持てなかった時期がありました。<br />
              特に、中学生時代は暴力や差別などを経験しとても悔しい思いをしました。<br />
              「医師になり多くの人の役に立ちたい」という夢を叶えるため毎日8時間以上の猛勉強を3年以上し続けた結果、東北大学医学部医学科に現役合格することができました。<br />
              <br />
              世界最先端の医療を学ぶことで患者様により多くの価値を提供したいという思いから、アメリカの名門Johns Hopkins大学に研究留学する機会をいただきました。そこで学んだ形成外科・美容外科の施術を日本に取り入れ、自分のように見た目で悩む人のコンプレックスをなくし自信をつけてもらいたいと思い、まずは約1年形成外科で研修しました。<br />
              その後大手美容外科に入職し浜松院の引き継ぎ・秋葉原院の立ち上げ・技術指導医として医師100名以上の指導を経験しました。また自分自身も二重整形・クマ取り・糸リフト・バッカルファット除去・鼻筋形成などの施術を経験し、どんどん見た目に自信をつけることができました。<br />
              <br />
              「100人いたら100人に満足していただきたい」そんな気持ちでひとりひとりの患者様のために全力を注いでおりました。<br />
              <br />
              自分が最初に二重整形を受けた大手美容外科、そして自分が働いていた別の大手美容外科での経験をもとに、更なる満足度の追求やより良い結果の追求をするためこの度T CLINICを開業することを決意しました。<br />
              <br />
              バレない黄金比無痛整形を追求しておりその結果1年でGoogle口コミ4.83/200件以上の評価をいただくことができました。どうしたらもっと患者様に喜んでもらえるのか？という気持ちで日々診療に励んでおり、またより満足してもらえるように倍率30倍以上での採用や協力会社をどんどん巻き込んで、よりよいクリニック作りをしております。今は新宿のみのクリニックですが、美容医療の不満足とコンプレックスをなくすために今後、全国にクリニックを展開していく予定です。<br />
              <br />
              みなさまにお会いできるのを心よりお待ちしております。
            </p>
            <div className={styles.career}>
              <div>経歴</div>
              <div>
                <ul>
                  <li>東北大学医学部医学科 卒業</li>
                  <li>Johns Hopkins 大学 留学</li>
                  <li>国際医療福祉大学病院</li>
                  <li>国際医療福祉大学三田病院 形成外科</li>
                  <li>大手美容皮膚科銀座院</li>
                  <li>大手美容外科浜松院 院長</li>
                  <li>大手美容外科秋葉原院 院長</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Doctor