import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const FacesInjection = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>脂肪溶解注射</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>脂肪溶解注射とは</h2>
            <p>
                脂肪の細胞膜を薬剤により破壊し、脂肪細胞の量を減らします。<br />
                デオキシコール酸が高濃度配合している厳選された薬剤を使用しています。<br />
                また、入れ方を10,000件以上の脂肪吸引の経験をもとに工夫することで注射でも確実に効果を出すことに成功しました。<br />
                脂肪溶解注射は複数回施術を重ねることで、脂肪細胞の数を確実に減らしていくことが出来ます。<br />
                平均3〜4回程度です。皮下脂肪が多い場合は脂肪吸引を提案することもございます。<br />
                脂肪細胞は一度減ると数が増えない為、半永久的に太りにくく浮腫みにくくなります。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-faces-injection-img.png' alt='about-area-faces-injection-img' className={styles.pc_img}/>
                <img src='/images/about-area-faces-injection-sp-img.png' alt='about-area-faces-injection-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />小顔にしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />注射だけで脂肪を減らしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />脂肪を減らしたいが長いダウンタイムがとれない方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />丸顔が気になる方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />ダイエットで痩せても顔だけ太って見える方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想のフェイスラインをお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまずフェイスラインの状態を確認し、患者様にあったを施術ご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望のフェイスラインに添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は10分程度になります。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>脂肪溶解注射の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>脂肪溶解注射スタンダード1cc(〜8cc)</div>
                    <div>¥7,480</div>
                </div>
                <div className={styles.price_list}>
                    <div>脂肪溶解注射スタンダード1cc(〜16cc)</div>
                    <div>¥6,930</div>
                </div>
                <div className={styles.price_list}>
                    <div>脂肪溶解注射スタンダード1cc(〜24cc)</div>
                    <div>¥5,830</div>
                </div>
                <div className={styles.price_list}>
                    <div>脂肪溶解注射プレミアム1cc(〜8cc)</div>
                    <div>¥9,680</div>
                </div>
                <div className={styles.price_list}>
                    <div>脂肪溶解注射プレミアム1cc(〜16cc)</div>
                    <div>¥9,130</div>
                </div>
                <div className={styles.price_list}>
                    <div>脂肪溶解注射プレミアム1cc(〜24cc)</div>
                    <div>¥8,030</div>
                </div>
                <ul className={styles.annotation}>
                    <li>スタンダード：鋭針で代謝を更新する薬剤を投与します。</li>
                    <li>プレミアム：マイクロカニューレでデオキシコール酸が高濃度配合されている薬剤を投与します。</li>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：1週間〜4ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・赤み、腫れ</li>
                        <li>・むくみ</li>
                        <li>・内出血</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default FacesInjection