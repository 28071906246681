import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const Whitening = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>顔医療脱毛/シミ取り</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>顔医療脱毛とは</h2>
            <p>
                厚労省の認可のあるジェントルマックスプロを使用し、顔の医療脱毛を行います。<br />
                1回で約20％の脱毛が可能と言われております。<br />
                通常約5回、髭などの濃い部分については約10回、回数が必要な場合もございます。<br />
                効果には個人差があります。
            </p>
            <h2>シミ取りとは</h2>
            <p>
                厚労省の認可のあるアレキサンドライトレーザーを使用し、シミ取りを行います。<br />
                複数回必要な場合がありますが、シミの濃さや深さ、色味などにより変わってきます。
            </p>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />何度も脱毛したけど実感できない方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />脱毛したい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />部分的に脱毛したい方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は30分程度になります。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>顔美白医療脱毛の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>ちょこっと顔医療脱毛(10ショットまで)</div>
                    <div>¥32,780</div>
                </div>
                <div className={styles.price_list}>
                    <div>顔医療脱毛1回</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>顔医療脱毛5回(10%オフ)</div>
                    <div>¥237,600</div>
                </div>
                <div className={styles.price_list}>
                    <div>顔医療脱毛10回(20%オフ)</div>
                    <div>¥422,400</div>
                </div>
                <div className={styles.price_list}>
                    <div>ちょこっとシミ取り(10ショットまで)</div>
                    <div>¥32,780</div>
                </div>
                <div className={styles.price_list}>
                    <div>顔シミ取り1回</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>顔シミ取り5回(10%オフ)</div>
                    <div>¥237,600</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・顔医療脱毛治療期間：1ヶ月〜5ヶ月<br />
                        ・シミ取り治療期間：1ヶ月〜6ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・痛み</li>
                        <li>・赤み</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default Whitening