import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/BurgerMenu.module.css';
import Button from '@mui/material/Button';

const BurgerMenu = ({ onClose }) => {
  return (
    <section className={styles.menu}>
      <div className={styles.close_button_wrapper}>
        <img src='/images//close.png' className={styles.close_button} alt='img' onClick={onClose}/>
      </div>

      <ul className={styles.menu_lists}>
        <li>
          <Link to="/" onClick={onClose}>
            Home
            <span>ホーム</span>
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={onClose}>
            About
            <span>当院について</span>
          </Link>
        </li>
        <li>
          <Link to="/case" onClick={onClose}>
            Case
            <span>症例写真</span>
          </Link>
        </li>
        <li>
          <Link to="/menu" onClick={onClose}>
            Menu&Price
            <span>施術・料金一覧</span>
          </Link>
        </li>
        <li>
          <Link to="/doctor" onClick={onClose}>
            Doctor
            <span>ドクター紹介</span>
          </Link>
        </li>
        <li>
          <Link to="/access" onClick={onClose}>
            Access
            <span>アクセス</span>
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={onClose}>
            Contact
            <span>お問い合わせ</span>
          </Link>
        </li>
      </ul>
      <div className={styles.btn_area}>
        <Button variant="contained" className={styles.line_button}>
          <Link to="https://page.line.me/862rmedt">
            <img src='/images/line-icon.png' className={styles.line_button_icon} alt='img' />LINE相談・予約
          </Link>
        </Button>
        <Button variant="contained" className={styles.web_button}>
          <Link to="https://connect.kireipass.jp/clinics/t-clinic-shinjuku/reservations/new?large_category_id=6185&menu_id=19150&small_category_id=0">WEB予約</Link>
        </Button>
        <Button variant="contained" className={styles.tel_button}>
          <Link to="tel:03-6228-0830">
            <span className={styles.time}>受付時間: 10:00~19:00(不定休)</span><br />
            TEL <span className={styles.num}>03-6228-0830</span>
          </Link>
        </Button>
      </div>
      <div>
        <small>&copy;2023 T CLINIC all rights reserved.</small>
      </div>
    </section>
  )
}

export default BurgerMenu
