import React from 'react'
import styles from '../styles/Menu.module.css';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../components/Breadcrumb';

const Menu = () => {
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            Menu & Price
            <span>施術メニュー・料金</span>
        </h2>
        <p>
          質の高い接客・圧倒的な仕上がり<br />
          満足できるアフターフォローをお届けします。
        </p>
      </section>
      <Breadcrumbs />

      <section className={styles.jump_link_area}>
        <ul>
          <li>
            <ScrollLink to='eye' smooth={true}>目の整形</ScrollLink>
          </li>
          <li>
            <ScrollLink to='face' smooth={true}>小顔整形</ScrollLink>
          </li>
          <li>
            <ScrollLink to='nose' smooth={true}>鼻の整形</ScrollLink>
          </li>
          <li>
            <ScrollLink to='hyaluronic' smooth={true}>ヒアルロン・ボトックス注射</ScrollLink>
          </li>
          <li>
            <ScrollLink to='potenza' smooth={true}>ポテンツァ</ScrollLink>
          </li>
          <li>
            <ScrollLink to='cell' smooth={true}>幹細胞点滴・治療</ScrollLink>
          </li>
          <li>
            <ScrollLink to='whitening' smooth={true}>顔脱毛・美白・シミ取り</ScrollLink>
          </li>
          <li>
            <ScrollLink to='mole' smooth={true}>ほくろ除去・いぼ除去</ScrollLink>
          </li>
        </ul>
      </section>

      <section className={styles.box_area}>
        <Element name='eye'>
          <img src='/images/menu-box-img.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/eyes-gratiodouble">黄金比二重術</Link>
            </li>
            <li>
              <Link to="/menu/eyes-sunekose">クマ取り注射/シワ取り注射</Link>
            </li>
            <li>
              <Link to="/menu/eyes-gratiocircle">切らない黄金比クマ取り</Link>
            </li>
            <li>
              <Link to="/menu/eyes-hyaluronic">ヒアルロン酸注射(涙袋)</Link>
            </li>
            <li>
              <Link to="/menu/eyes-innercorner">黄金比目頭切開</Link>
            </li>
            <li>
              <Link to="/menu/eyes-outercorner">黄金比目尻切開</Link>
            </li>
            <li>
              <Link to="/menu/eyes-gratiodrop">黄金比目尻切開・タレ目形成</Link>
            </li>
            <li>
              <Link to="/menu/eyes-eyelid">まぶたの脂肪取り</Link>
            </li>
            <li>
              <Link to="/menu/eyes-eyebrow">眉下切開</Link>
            </li>
            <li>
              <Link to="/menu/eyes-incision">黄金比二重切開法</Link>
            </li>
            {/* <li>
              <Link to="#">目の下のクマ取り切開法</Link>
            </li> */}
          </ul>
        </Element>

        <Element name='face'>
          <img src='/images/menu-box-img2.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/faces-tthread">バレない黄金比無痛リフト</Link>
            </li>
            <li>
              <Link to="/menu/faces-suction">黄金比脂肪吸引</Link>
            </li>
            <li>
              <Link to="/menu/faces-gratiosuction">バレない黄金比脂肪吸引</Link>
            </li>
            <li>
              <Link to="/menu/faces-buccalfat">バッカルファット除去</Link>
            </li>
            <li>
              <Link to="/menu/faces-eline">バレないEライン形成</Link>
            </li>
            <li>
              <Link to="/menu/faces-hifu">HIFU</Link>
            </li>
            <li>
              <Link to="/menu/faces-botox">エラボトックス</Link>
            </li>
            <li>
              <Link to="/menu/faces-injection">脂肪溶解注射</Link>
            </li>
            <li>
              <Link to="/menu/faces-gratiolift">バレない黄金比小顔術</Link>
            </li>
          </ul>
        </Element>

        <Element name='nose'>
          <img src='/images/menu-box-img3.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/nose-gratiotip">黄金比鼻尖形成</Link>
            </li>
            <li>
              <Link to="/menu/nose-implantation">黄金比鼻先移植術/鼻柱下降術</Link>
            </li>
            <li>
              <Link to="/menu/nose-grationose">黄金比小鼻縮小</Link>
            </li>
            <li>
              <Link to="/menu/nose-bridge">黄金比鼻筋形成術</Link>
            </li>
          </ul>
        </Element>

        <Element name='hyaluronic'>
          <img src='/images/menu-box-img4.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/injection-hyaluronic">ヒアルロン酸注射</Link>
            </li>
            <li>
              <Link to="/menu/injection-botox">ボトックス注射</Link>
            </li>
          </ul>
        </Element>

        <Element name='potenza'>
          <img src='/images/menu-box-img5.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/potenza">ポテンツァ</Link>
            </li>
          </ul>
        </Element>

        <Element name='cell'>
          <img src='/images/menu-box-img6.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/cellnatant">エクソソーム点滴</Link>
            </li>
            <li>
              <Link to="/menu/cell">幹細胞点滴</Link>
            </li>
          </ul>
        </Element>

        <Element name='whitening'>
          <img src='/images/menu-box-img7.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/whitening">顔医療脱毛/シミ取り</Link>
            </li>
          </ul>
        </Element>

        <Element name='mole'>
          <img src='/images/menu-box-img8.png' className={styles.box_img} alt='img'/>
          <ul>
            <li>
              <Link to="/menu/mole">ほくろ除去・いぼ除去</Link>
            </li>
          </ul>
        </Element>
        
      </section>
    </>
  )
}

export default Menu