import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Case from "./pages/Case";
import Doctor from "./pages/Doctor";
import Access from "./pages/Access";
import Contact from "./pages/Contact";
import Confirm from "./pages/contact/Confirm";
import Complete from "./pages/contact/Complete";
import Menu from "./pages/Menu";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Test from "./pages/detail/Test";
import EyesGratioDouble from "./pages/detail/EyesGratioDouble";
import EyesSunekos from "./pages/detail/EyesSunekos";
import EyesGratioCircle from "./pages/detail/EyesGratioCircle";
import EyesHyaluronic from "./pages/detail/EyesHyaluronic";
import EyesInnerCorner from "./pages/detail/EyesInnerCorner";
import EyesOuterCorner from "./pages/detail/EyesOuterCorner";
import EyesGratioDrop from "./pages/detail/EyesGratioDrop";
import EyesEyelid from "./pages/detail/EyesEyelid";
import EyesEyebrow from "./pages/detail/EyesEyebrow";
import EyesIncision from "./pages/detail/EyesIncision";
import FacesTthread from "./pages/detail/FacesTthread";
import FacesSuction from "./pages/detail/FacesSuction";
import FacesGratioSuction from "./pages/detail/FacesGratioSuction";
import FacesBuccalFat from "./pages/detail/FacesBuccalFat";
import FacesEline from "./pages/detail/FacesEline";
import FacesHifu from "./pages/detail/FacesHifu";
import FacesBotox from "./pages/detail/FacesBotox";
import FacesInjection from "./pages/detail/FacesInjection";
import FacesGratioLift from "./pages/detail/FacesGratioLift";
import NoseGratioTip from "./pages/detail/NoseGratioTip";
import NoseImplantation from "./pages/detail/NoseImplantation";
import NoseGratioNose from "./pages/detail/NoseGratioNose";
import NoseBridge from "./pages/detail/NoseBridge";
import InjectionHyaluronic from "./pages/detail/InjectionHyaluronic";
import InjectionBotox from "./pages/detail/InjectionBotox";
import Potenza from "./pages/detail/Potenza";
import Cellnatant from "./pages/detail/Cellnatant";
import Cell from "./pages/detail/Cell";
import Whitening from "./pages/detail/Whitening";
import Mole from "./pages/detail/Mole";


function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header /> {/* 共通ヘッダー */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/test/test" element={<Test />} />
        <Route path="/menu/eyes-gratiodouble" element={<EyesGratioDouble />} />{/* メニュー詳細 */}
        <Route path="/menu/eyes-sunekose" element={<EyesSunekos />} />
        <Route path="/menu/eyes-gratiocircle" element={<EyesGratioCircle />} />
        <Route path="/menu/eyes-hyaluronic" element={<EyesHyaluronic />} />
        <Route path="/menu/eyes-innercorner" element={<EyesInnerCorner />} />
        <Route path="/menu/eyes-outercorner" element={<EyesOuterCorner />} />
        <Route path="/menu/eyes-gratiodrop" element={<EyesGratioDrop />} />
        <Route path="/menu/eyes-eyelid" element={<EyesEyelid />} />
        <Route path="/menu/eyes-eyebrow" element={<EyesEyebrow />} />
        <Route path="/menu/eyes-incision" element={<EyesIncision />} />
        <Route path="/menu/faces-tthread" element={<FacesTthread />} />
        <Route path="/menu/faces-suction" element={<FacesSuction />} />
        <Route path="/menu/faces-gratiosuction" element={<FacesGratioSuction />} />
        <Route path="/menu/faces-buccalfat" element={<FacesBuccalFat />} />
        <Route path="/menu/faces-eline" element={<FacesEline />} />
        <Route path="/menu/faces-hifu" element={<FacesHifu />} />
        <Route path="/menu/faces-botox" element={<FacesBotox />} />
        <Route path="/menu/faces-injection" element={<FacesInjection />} />
        <Route path="/menu/faces-gratiolift" element={<FacesGratioLift />} />
        <Route path="/menu/nose-gratiotip" element={<NoseGratioTip />} />
        <Route path="/menu/nose-implantation" element={<NoseImplantation />} />
        <Route path="/menu/nose-grationose" element={<NoseGratioNose />} />
        <Route path="/menu/nose-bridge" element={<NoseBridge />} />
        <Route path="/menu/injection-hyaluronic" element={<InjectionHyaluronic />} />
        <Route path="/menu/injection-botox" element={<InjectionBotox />} />
        <Route path="/menu/potenza" element={<Potenza />} />
        <Route path="/menu/cellnatant" element={<Cellnatant />} />
        <Route path="/menu/cell" element={<Cell />} />
        <Route path="/menu/whitening" element={<Whitening />} />
        <Route path="/menu/mole" element={<Mole />} />
        <Route path="/case" element={<Case />} />
        <Route path="/doctor" element={<Doctor />} />
        <Route path="/access" element={<Access />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact/confirm" element={<Confirm />} />
        <Route path="/contact/complete" element={<Complete />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
