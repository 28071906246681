import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const NoseGratioNose = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>黄金比小鼻縮小</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>黄金比小鼻縮小とは</h2>
            <p>
                小鼻の張り出しや小鼻の広がりを糸もしくは切開により抑えることで、小鼻を小さくし黄金比に合わせます。<br />
                バランスを整えるために鼻先の整形との併用がおすすめな場合もございます。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-nose-grationose-img.png' alt='about-area-nose-grationose-img' className={styles.pc_img}/>
                <img src='/images/about-area-nose-grationose-sp-img.png' alt='about-area-nose-grationose-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />ダウンタイムを最小限に小鼻を小さくしたい方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想の小鼻をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず鼻の状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望の小鼻に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は30-60分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>黄金比小鼻縮小の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>黄金比小鼻縮小</div>
                    <div>¥217,800〜¥657,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>バレない黄金比小鼻縮小1本(埋没法)</div>
                    <div>¥217,800<span>モニター価格 ¥198,000</span></div>
                </div>
                <div className={styles.price_list}>
                    <div>黄金比小鼻縮小内側切開法</div>
                    <div>¥492,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>黄金比小鼻縮小外側切開法</div>
                    <div>¥547,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>黄金比小鼻縮小内外側切開法</div>
                    <div>¥657,800</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
                
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：1ヶ月〜6ヶ月<br />
                        ・通院：1回(切開法は2回)
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・腫れ</li>
                        <li>・痛み</li>
                        <li>・むくみ</li>
                        <li>・出血</li>
                        <li>・傷跡や赤みが残る</li>
                        <li>・盛り上がりや糸の跡が残る可能性</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default NoseGratioNose