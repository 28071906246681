import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const EyesGratioDrop = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>黄金比目尻切開・タレ目形成</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>黄金比目尻切開・タレ目形成とは</h2>
            <p>
                ひとりひとりに最適な黄金比に合わせ、左右対称になるようにデザインし施術を行います。<br />
                また、針を極力細くし麻酔液も複数の論文のデータをもとに調整することで、痛みに最大限配慮しています。<br />
                また、10,000件以上の施術経験と100を超える論文をデータをもとにしたT CLINIC独自の施術により腫れや内出血を最小限にし、従来よりも傷が目立ちにくく、自然で美しい目元にすることができます。<br />
                目尻切開ではひとりひとりの目元の状態や左右差に合わせてデザインし、横幅を最大限に広げます。<br />
                またタレ目形成では、下眼瞼の瞼板とCPFを適切に露出し、糸で短縮することでタレ目にすることが可能です。<br />
                両方同時に行うことで、後戻りがしにくくなり、より大きな目元になることが可能です。<br />
                平均1〜4日が腫れのピーク、5〜7日でほとんど腫れは引いてきます。抜糸は5〜14日後です。<br />
                1ヶ月で腫れは完全になくなりますが、2〜3ヶ月でコラーゲンが生成されるのでより自然で仕上がりが良くなると言われております。<br />
                傷の赤みや盛り上がりは半年から1年で改善しますが、乾燥すると治りが悪くなるので保湿を積極的にしてください。<br />
                ただしダウンタイムには個人差がありますので、経過には多少の前後がございます。<br />
                腫れや内出血を最小限にする内服薬・外用薬・点滴の用意もございます。<br />
                T CLINICでは、ひとりひとりへのカウンセリング・施術前のシミュレーション・施術を時間をかけて丁寧に行っております。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-eyes-gratiodrop-img.png' alt='about-area-eyes-gratiodrop-img' className={styles.pc_img}/>
                <img src='/images/about-area-eyes-gratiodrop-sp-img.png' alt='about-area-eyes-gratiodrop-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />ダウンタイムが少ない目尻切開がしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />目を大きくしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />目尻切開をしたことがあり、少しだけ変えたい方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想のタレ目をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず目もとの状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望のタレ目形の形に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は40-60分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>黄金比タレ目形成の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>切らない黄金比タレ目形成ベーシック</div>
                    <div>¥217,800<span>モニター価格 ¥198,800</span></div>
                </div>
                <div className={styles.price_list}>
                    <div>切らない黄金比タレ目形成ロング</div>
                    <div>¥382,800<span>モニター価格 ¥348,800</span></div>
                </div>
                <ul className={styles.annotation}>
                    <li>※ベーシック：糸1本</li>
                    <li>※ロング：糸2本以上</li>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：2週間〜1ヶ月<br />
                        ・抜糸(通院)：約1週間後
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・腫れや内出血</li>
                        <li>・傷跡や赤み</li>
                        <li>・糸の跡が残る可能性</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default EyesGratioDrop