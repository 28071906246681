import React from 'react'
import styles from '../styles/About.module.css';
import { Breadcrumbs } from '../components/Breadcrumb';

const About = () => {
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            About
            <span>当院について</span>
        </h2>
        <p>
          患者様を幸せにし、<br />
          満足度日本一のクリニックを目指します。
        </p>
      </section>
      <Breadcrumbs />
      {/* <section className={styles.team_area}>
          <h2>
            Management Team
            <span>経営陣</span>
          </h2>
          <div className={styles.prof_items}>
            <div className={styles.prof_item}>
              <img src='/images/prof-item-img.png' alt='Img' className={styles.prof_item_img} />
              <div className={styles.prof_item_title}>共同創業者</div>
              <div className={styles.prof_item_name}>三上 悠亜</div>
              <div className={styles.prof_item_text}>
                なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。
                なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。
              </div>
              
            </div>
            <div className={styles.prof_item}>
              <img src='/images/prof-item-img.png' alt='Img' className={styles.prof_item_img} />
              <div className={styles.prof_item_title}>共同創業者</div>
              <div className={styles.prof_item_name}>三上 悠亜</div>
              <div className={styles.prof_item_text}>
                なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。
                なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。
              </div>
              
            </div>
            <div className={styles.prof_item}>
              <img src='/images/prof-item-img.png' alt='Img' className={styles.prof_item_img} />
              <div className={styles.prof_item_title}>共同創業者</div>
              <div className={styles.prof_item_name}>三上 悠亜</div>
              <div className={styles.prof_item_text}>
                なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。
                なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。なぜクリニックを始めたかが入る。
              </div>
              
            </div>
          </div>
      </section> */}
      <section className={styles.about_area}>
        <div>
          <div className={styles.title}>患者さまの幸福を追求し、美容医療の不幸を無くす</div>
          <div className={styles.sub_title}>「鏡を見た自分を好きになる」</div>
          <p>
            毎朝鏡を見た際に、コンプレックスでお悩みの患者さまはとても多いと感じます。<br />
            院長の私自身も元々コンプレックスを持っており自分に自信がありませんでした。<br />
            自分に自信がないことが根底にあり、対人関係も上手くいきませんでした。<br />
            ある日整形をしたことがきっかけでコンプレックスがなくなり、<br />
            自分に自信がつき、その結果全ての行動が変わりました。<br />
            対人関係や仕事も全てが順調になりました。<br />
            自分と同じような思いを持った患者様を1人でも多く幸せにしたい。<br />
            そんな思いがあります。
          </p>
          <img src='/images/t-mark-img.png' className={styles.t_mark_img} alt='img'/>
        </div>
      </section>

      <section className={styles.policy_area}>
        <div>
          <img src='/images/policy-img.png' className={styles.policy_img} alt='img'/>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            Philosophy
            <span>理念</span>
          </div>
          <div className={styles.text}>
            T CLINICに関わるすべての人に感動と自信を届ける。<br />
            質の高い接客・圧倒的な仕上がり・満足できるアフターフォローをハイクオリティでお届けする。
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>
            Vision
            <span>ビジョン</span>
          </div>
          <div className={styles.text}>
            美容医療の不満足とコンプレックスをなくす、顔整形ハイクオリティを目指します。
          </div>
        </div>
      </section>

      <section className={styles.doctor_area}>
        <div className={styles.head}>
          <h2>
            Doctor
            <span>ドクター</span>
          </h2>
        </div>
        <div className={styles.content}>
          <span>院長</span>
          <div className={styles.name}>
            相良卓哉
            <span>Takuya Sagara</span>
          </div>
          <p>
            T CLINICに関わるすべての人に感動と自信を届ける。<br />
            質の高い接客・圧倒的な仕上がり・満足できるアフターフォローをNo.1のクオリティでお届けします。
          </p>
        </div>
        <img src='/images/doctor-img.png' className={styles.doctor_img} alt='img'/>
      </section>

      <section className={styles.facility_area}>
        <h2>
          Facilities
          <span>院内設備</span>
        </h2>
        <div className={styles.facility_contents}>
          <img src='/images/facility-img.png' className={styles.facility_img} alt='img'/>
          <img src='/images/facility-img2.png' className={styles.facility_img} alt='img'/>
          <img src='/images/facility-img3.png' className={styles.facility_img} alt='img'/>
          <img src='/images/facility-img4.png' className={styles.facility_img} alt='img'/>
          <img src='/images/facility-img5.png' className={styles.facility_img} alt='img'/>
          <img src='/images/facility-img4.png' className={styles.facility_img} alt='img'/>
        </div>

      </section>

    </>
  )
}

export default About