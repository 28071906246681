import React,{ useState } from 'react'
import styles from '../styles/Home.module.css';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

const Home = () => {
  const [showMore, setShowMore] = useState(false);

  // Assume that faqs is an array containing your FAQ data
  const faqs = [
      {
          question: 'Q.カウンセリングと施術を同日に受けることはできますか？',
          answer: 'A.当日に施術をご検討いただいている方は手術室の空き状況次第のご案内となります。空きがない場合やお待ち時間によっては別日のご案内をする可能性がございます。当日施術を希望の場合、二重のりは使用せずにご来院をお願いします。',
          // ... More data ...
      },
      {
          question: 'Q.健康保険は適用されますか？',
          answer: 'A.怪我や病気の治療ではなく、適用されません。',
          // ... More data ...
      },
      {
          question: 'Q.男性も診療はできますか？',
          answer: 'A.男性でもぜひお気軽にご来院ください。男性メニューも取り扱っております。',
          // ... More data ...
      },
      {
          question: 'Q.手術、施術前の飲酒は可能ですか？',
          answer: 'A.手術前日、当日は肝臓に負担を与えるため飲酒はお控えください。また飲酒すると術後はむくみが増強してしまう可能性があります。',
          // ... More data ...
      },
      {
          question: 'Q.予約しないでも診察してもらえますか？',
          answer: 'A.ご予約の方が優先となりますので、お待ち時間が発生する場合があります。 当日でも構いませんので事前にご予約をお願いいたします。',
          // ... More data ...
      },
      {
          question: 'Q.過去に埋没手術を受けたことがあります。また、受けることは可能でしょうか？',
          answer: 'A.手術可能なケースが多いです。一度診察にお越しくださいませ。手術回数が多い場合や希望のデザインによっては全切開など別の治療のご案内になる場合もございます。',
          // ... More data ...
      },
      {
          question: 'Q.左右で二重の幅が違うのですが、埋没法で直せますか？',
          answer: 'A.ほとんどの場合、二重の手術で左右のバランスをみて整えることが可能です。まぶたが分厚い場合などは切開する方法をご提案する場合もありますので、診察の上でご本人様に合った方法をご提案させていただきます。',
          // ... More data ...
      },
      {
          question: 'Q.術後のダウンタイムはどんな感じですか？',
          answer: 'A.当院は術後の腫れを最小限に抑えています。施術当日でもメイクしてから帰っていただくことも可能です。',
          // ... More data ...
      },
      {
          question: 'Q.痛みに弱いのですが、大丈夫でしょうか？',
          answer: 'A.当院では麻酔を使用して糸や針が細い独自の施術を行うため、痛みを最小限に抑えた状態でリラックスして治療をお受けいただくことができます。',
          // ... More data ...
      },
      {
          question: 'Q.カウンセリングは必ず費用がかかりますか？',
          answer: 'A.無料カウンセリングだけでも大丈夫です。まずは理想の二重が手に入れられるか確かめていただくことが大切なので、お気軽に無料カウンセリングへお越しください。',
          // ... More data ...
      },
      {
          question: 'Q.脂肪をとったことで、将来たるんだりしませんか？',
          answer: 'A.脂肪吸引をすることが、将来たるみの原因となることはございません。むしろ、脂肪は将来的なたるみの原因となる可能性があり、脂肪を取ることによってほうれい線やマリオネットラインのシワ防止が期待出来ます。',
          // ... More data ...
      },
      {
          question: 'Q.脂肪吸引と脂肪溶解注射はどう違いますか？',
          answer: 'A.脂肪吸引は、管を挿入して脂肪を吸引する手術です。一方、脂肪溶解注射は、薬剤を注入して脂肪を分解し、自然に排出することを目的とした施術です。脂肪吸引は比較的劇的な効果があり、一度の施術で大量の脂肪を取り除くことができますが、通常は手術によるダウンタイムが必要です（TCLINICではダウンタイムを最小限に抑えています）。脂肪溶解注射は施術後のダウンタイムがほとんどなく、細かい調整に優れていることが特徴です。脂肪溶解注射は軽度の脂肪取り除きや、細かいパーツのコンディショニングに適しています。どちらをご希望の場合でも、丁寧にカウンセリングしたうえで、患者様にあった施術をご提案いたします。',
          // ... More data ...
      },
      {
          question: 'Q.小顔脂肪吸引は誰でも受けられますか？',
          answer: 'A.健康状態や脂肪の量、肌の弾力性などによって、施術ができる場合とできない場合があります。丁寧にカウンセリングしたうえで、施術の可否を判断いたします。特に、妊娠中や授乳中、糖尿糖疾患や心臓病、高血圧などの持病がある場合、または薬物治療を受けている場合にはご相談ください。',
          // ... More data ...
      },
  
  ];
  return (
    <>
      <section className={styles.fv_area}>
        <img src='/images/fv-img.png' alt='img' className={styles.fv_img} />
        <img src='/images/fv-sp-img.png' alt='img' className={styles.fv_sp_img} />
      </section>
      {/* <section className={styles.team_area}>
          <h2>
            Management Team
            <span>経営陣</span>
          </h2>
          <div className={styles.prof_items}>
            <div className={styles.prof_item}>
              <img src='/images/prof-item-img.png' alt='Img' className={styles.prof_item_img} />
              <div className={styles.prof_item_name}>三上 悠亜</div>
              <div className={styles.prof_item_title}>共同創業者</div>
            </div>
            <div className={styles.prof_item}>
              <img src='/images/prof-item-img.png' alt='Img' className={styles.prof_item_img} />
              <div className={styles.prof_item_name}>三上 悠亜</div>
              <div className={styles.prof_item_title}>共同創業者</div>
            </div>
            <div className={styles.prof_item}>
              <img src='/images/prof-item-img.png' alt='Img' className={styles.prof_item_img} />
              <div className={styles.prof_item_name}>三上 悠亜</div>
              <div className={styles.prof_item_title}>共同創業者</div>
            </div>
          </div>
      </section> */}
      {/* <section className={styles.banner_area}>
        <img src='/images/banner.png' className={styles.banner} alt='img' />
      </section> */}
      <section className={styles.about_area}>
        <div className={styles.about_text}>
          <h2>
            About
            <span>当院について</span>
          </h2>
          <div className={styles.about_text_title}>
            美容医療の不満足とコンプレックスをなくす顔整形日本一のクリニックを目指します。
          </div>
          <p>
            感動と信頼の追求<br />
            期待の2倍以上価値のある接客・仕上がり・アフターフォローをハイクオリティで提供し続けることで、患者様に一切のストレスを与えずに成果に貢献し、全スタッフの成長と物心両面の幸福の追求、社会への貢献を目的とします。
          </p>
          <Button variant="contained" disableElevation className={styles.about_btn}>
            <Link to="/about">View more</Link>
          </Button>
        </div>
      </section>
      <section className={styles.menu_area}>
          <h2>
            Menu
            <span>施術メニュー・料金</span>
          </h2>
          <div>
            <img src='/images/menu-img.png' alt='img' className={styles.menu_img} />
            <img src='/images/menu-sp-img.png' alt='img' className={styles.menu_sp_img} />
          </div>
          <Button variant="contained" disableElevation className={styles.menu_btn}>
            <Link to="/menu">View more</Link>
          </Button>
        </section>

        <section className={styles.case_area}>
          <h2>
            Case
            <span>症例写真</span>
          </h2>
          <div className={styles.case_contents}>
            <div className={styles.case_content}>
              <div className={styles.case_title}>黄金比<br/>二重整形</div>
              <div>
                <img src='/images/case-img.png' className={styles.case_img} alt='img' />
              </div>
            </div>

            <div className={styles.case_content}>
              <div className={styles.case_title}>黄金比<br/>二重整形</div>
              <div>
                <img src='/images/case-img2.png' className={styles.case_img} alt='img' />
              </div>
              
            </div>

            <div className={styles.case_content}>
              <div className={styles.case_title}>切らない黄金比<br/>クマ取り</div>
              <div>
                <img src='/images/case-img3.png' className={styles.case_img} alt='img' />
              </div>
            </div>

            <div className={styles.case_content}>
              <div className={styles.case_title}>鼻筋<br/>形成術</div>
              <div>
                <img src='/images/case-img4.png' className={styles.case_img} alt='img' />
              </div>
            </div>

            <div className={styles.case_content}>
              <div className={styles.case_title}>バッカルファット除去<br/>+Tスレッド</div>
              <div>
                <img src='/images/case-img5.png' className={styles.case_img} alt='img' />
              </div>
            </div>
          </div>

          <Button variant="contained" disableElevation className={styles.case_btn}>
            <Link to="/case">View more</Link>
          </Button>
        </section>

        <section className={styles.faq_area}>
            <h2>FAQ <span>よくあるご質問</span></h2>
            {
                faqs.map((faq, index) => {
                    if (!showMore && index >= 5) return null;
                    return (
                        <Accordion key={index} className={styles.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}-content`}
                                id={`panel${index + 1}-header`}
                                className={styles.accordion_title}
                            >
                                <Typography>{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={styles.accordion_text}>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })
            }
            <Button 
                variant="contained" 
                disableElevation 
                className={styles.faq_btn} 
                onClick={() => setShowMore(!showMore)}
            >
                {showMore ? 'View less' : 'View more'}
            </Button>
        </section>
    </>
  )
}

export default Home