import { useLocation, Link } from "react-router-dom";
import styles from '../styles/Breadcrumb.module.css';

export function Breadcrumbs() {
  const breadcrumbNameMap = {
    home: "ホーム",
    about: "当院について",
    menu: "施術メニュー・料金",
    'eyes-gratiodouble': '黄金比二重術',
    'eyes-sunekose': 'クマ取り注射/シワ取り注射',
    'eyes-gratiocircle': '切らない黄金比クマ取り',
    'eyes-hyaluronic': 'ヒアルロン酸注射(涙袋)',
    'eyes-innercorner': '黄金比目頭切開',
    'eyes-outercorner': '黄金比目尻切開',
    'eyes-gratiodrop': '黄金比目尻切開・タレ目形成',
    'eyes-eyelid': 'まぶたの脂肪取り',
    'eyes-eyebrow': '眉下切開',
    'eyes-incision': '黄金比二重切開法',
    'faces-tthread': 'バレない黄金比無痛リフト',
    'faces-suction': '黄金比脂肪吸引',
    'faces-gratiosuction': 'バレない黄金比脂肪吸引',
    'faces-buccalfat': 'バッカルファット除去',
    'faces-eline': 'バレないEライン形成',
    'faces-hifu': 'HIFU',
    'faces-botox': 'エラボトックス',
    'faces-injection': '脂肪溶解注射',
    'faces-gratiolift': 'バレない黄金比小顔術',
    'nose-gratiotip': '黄金比鼻尖形成',
    'nose-implantation': '黄金比鼻先移植術/鼻柱下降術',
    'nose-grationose': '黄金比小鼻縮小',
    'nose-bridge': '黄金比鼻筋形成術',
    'injection-hyaluronic': 'ヒアルロン酸注射',
    'injection-botox': 'ボトックス注射',
    'potenza': 'ポテンツァ',
    'cellnatant': 'エクソソーム点滴',
    'cell': '幹細胞点滴',
    'whitening': '顔医療脱毛/シミ取り',
    'mole': 'ほくろ除去・いぼ除去',
    case: "症例写真",
    doctor: "ドクター紹介",
    access: "アクセス",
    contact: "お問い合わせ",
    confirm: "お問い合わせ確認",
    complete: "送信完了",
  };
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  
  return (
    <section className={styles.breadcrumb_area}>
      <Link to="/">ホーム</Link>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        // マッピングから表示名を取得、マッピングが存在しない場合は"NotFound"を使用
        const name = breadcrumbNameMap[value] || "お探しのページは見つかりませんでした";

        return (
          <span key={to}>
            <span> &gt; </span>
            <Link to={to}>{name}</Link>
          </span>
        );
      })}
    </section>
  );
}