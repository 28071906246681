import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const FacesBuccalFat = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>バッカルファット除去</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>バッカルファット除去とは</h2>
            <p>
                バッカルファット除去は、頬や口元のたるみの原因となる脂肪を取り除くことで、シャープなフェイスラインを手に入れる施術です。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-faces-buccalfat-img.png' alt='about-area-faces-buccalfat-img' className={styles.pc_img}/>
                <img src='/images/about-area-faces-buccalfat-sp-img.png' alt='about-area-faces-buccalfat-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />小顔にしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />将来ブルドック顔になるのを予防したい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />1回の施術で脂肪を確実減らしたいが、脂肪吸引のダウンタイムはとれない方。
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />脂肪を少しだけ減らしたいが、こけるのが心配な方。
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想のフェイスラインをお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまずフェイスラインの状態を確認し、患者様にあったを施術ご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望のフェイスラインに添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は30分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        <Element className={styles.case_area} name='case'>
            <h2>バッカルファット除去の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/faces-tthread-img.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/faces-tthread-img2.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/faces-tthread-img3.jpg' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element>

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>バッカルファット除去スタンダード</div>
                    <div>¥217,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>バッカルファット除去プレミアム</div>
                    <div>¥382,800</div>
                </div>
                <ul className={styles.annotation}>
                    <li>スタンダード：傷が大きく、一定量のみ切除</li>
                    <li>プレミアム：傷が比較的小さく、左右差や顔の状態に合わせて調整</li>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：1ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・局所麻酔によるアレルギー反応を起こす可能性</li>
                        <li>・二次感染の可能性</li>
                        <li>・血腫</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default FacesBuccalFat