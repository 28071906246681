import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const EyesGratioDouble = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>黄金比二重術</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>黄金比二重術（二重埋没法）とは</h2>
            <p>
                ひとりひとりに最適な黄金比に合わせ左右対称になるようにデザインし施術を行います。<br />
                また針を極力細くし、麻酔液も複数の論文のデータをもとに調整することで、まぶたへの負担を少なく痛みに最大限配慮しています。 <br />
                また10,000件以上の施術経験と100を超える論文をデータをもとにしたT CLINIC独自の二重埋没法により、腫れや内出血を最小限にし、従来よりもモチの良い自然で美しい二重にすることができます。<br />
                バレない黄金比二重術では皮膚に一切の傷をつけず、また生まれ持った二重を再現するために挙筋腱膜から糸をかけます。<br />
                また糸を交差させることでモチを最大化し、自然癒着を促しております。<br />
                平均1〜3日が腫れのピーク、4〜7日でほとんど腫れは引いてきます。<br />
                内出血が出た場合は1〜2週間で改善しますので、生じた際には濃いメイクでカバーをおすすめします。<br />
                1ヶ月で腫れは完全になくなりますが、2〜3ヶ月でコラーゲンが生成されるのでより自然で仕上がりが良くなると言われております。<br />
                ただしダウンタイムには個人差がありますので、経過には多少の前後がございます。<br />
                腫れや内出血を最小限にする内服薬・外用薬・点滴の用意もございます。<br />
                T CLINICではひとりひとりへのカウンセリング・施術前のシミュレーション・施術を時間をかけて丁寧に行っております。 
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-eyes-gratiodouble-img.png' alt='about-area-eyes-gratiodouble-img' className={styles.pc_img}/>
                <img src='/images/about-area-eyes-gratiodouble-sp-img.png' alt='about-area-eyes-gratiodouble-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />目の開きを良くし左右対称の二重になりたい
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />アイプチなしでバレずに生まれ持ったような二重にしたい
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />二重整形の修正をしたい（他院修正）
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想の二重をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず目もとの状態を確認し、バーチャルスティックを使用して患者様にあった二重の形をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望の二重の形に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は15分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        <Element className={styles.case_area} name='case'>
            <h2>黄金比二重術の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element>

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>黄金比二重術</div>
                    <div>¥53,900〜¥437,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>T CLINIC二重術 片目</div>
                    <div>¥53,900<span>モニター価格 ¥45,000</span></div>
                </div>
                <div className={styles.price_list}>
                    <div>T CLINIC二重術 両目</div>
                    <div>¥107,800<span>モニター価格 ¥90,000</span></div>
                </div>
                <div className={styles.price_list}>
                    <div>バレない黄金比二重術</div>
                    <div>シークレットシングル ¥162,800<span>モニター価格 ¥150,000</span></div>
                </div>
                <div className={styles.price_list}>
                    <div>バレない黄金比二重術</div>
                    <div>シークレットクロス ¥305,800<span>モニター価格 ¥288,000</span></div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>

            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：2週間〜1ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・瞼の腫れ</li>
                        <li>・瞼の内出血</li>
                        <li>・目の中のゴロゴロ感</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default EyesGratioDouble