import React from 'react'
import styles from '../styles/Case.module.css';
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../components/Breadcrumb';

const Case = () => {
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            Case
            <span>症例写真</span>
        </h2>
        <p>
          症例実績累計1万件以上<br />
          許可をいただいた患者様の症例写真を紹介します。
        </p>
      </section>
      <Breadcrumbs />

      <section className={styles.jump_link_area}>
        <ul>
          <li>
            <ScrollLink to="eye" smooth={true}>目の整形</ScrollLink>
          </li>
          <li>
            <ScrollLink to="small-face" smooth={true}>小顔整形</ScrollLink>
          </li>
          {/* <li>
            <ScrollLink to="nose" smooth={true}>鼻の整形</ScrollLink>
          </li>
          <li>
            <ScrollLink to="hyaluron-botox" smooth={true}>ヒアルロン・ボトックス注射</ScrollLink>
          </li>
          <li>
            <ScrollLink to="potenza" smooth={true}>ポテンツァ</ScrollLink>
          </li>
          <li>
            <ScrollLink to="stem-cell" smooth={true}>幹細胞点滴・治療</ScrollLink>
          </li>
          <li>
            <ScrollLink to="facial-hair-removal" smooth={true}>顔脱毛・美白・シミ取り</ScrollLink>
          </li>
          <li>
            <ScrollLink to="mole-removal" smooth={true}>ほくろ除去・いぼ除去</ScrollLink>
          </li> */}
        </ul>
      </section>

      <section className={styles.eye_area}>
        <Element className={`${styles.head} ${styles.eye_head}`} name='eye'>目の整形</Element>
        <div className={styles.content}>
          <div className={styles.flex}>
            <div className={styles.item}>
              <div>
                <img src='/images/eye-area-img.png' className={styles.item_img} alt='img' />
              </div>
              <div className={styles.title}>黄金比二重整形</div>
              {/* <p>
              </p> */}
            </div>

            <div className={styles.item}>
              <div>
                <img src='/images/eye-area-img2.png' className={styles.item_img} alt='img' />
              </div>
              <div className={styles.title}>黄金比二重整形</div>
              {/* <p>
              </p> */}
            </div>

            <div className={styles.item}>
              <div>
                <img src='/images/eye-area-img3.png' className={styles.item_img} alt='img' />
              </div>
              <div className={styles.title}>黄金比二重整形</div>
              {/* <p>
              </p> */}
            </div>
            </div>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/eye-area-img4.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>切らない黄金比クマ取り</div>
                {/* <p>
                </p> */}
              </div>
              <div className={styles.item}>
                <div>
                  <img src='/images/eye-area-img5.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>クマ取り注射SUNEKOS</div>
                {/* <p>
                </p> */}
              </div>
              <div className={styles.item}>
                <div>
                  <img src='/images/eye-area-img6.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>1DAYクマ取り</div>
                {/* <p>
                </p> */}
              </div>
            </div>

          </div>

          <Element className={`${styles.head} ${styles.face_head}`} name='small-face'>小顔整形</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/face-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                {/* <p>
                </p> */}
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/face-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                {/* <p>
                </p> */}
              </div>
            </div>
          </div>

          {/* <Element className={`${styles.head} ${styles.nose_head}`} name='nose'>鼻の整形</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/nose-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/nose-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/nose-area-img3.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>
            </div>
          </div>

          <Element className={`${styles.head} ${styles.nose_head}`} name='hyaluron-botox'>ヒアルロン・ボトックス・注射</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img3.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>
            </div>
          </div>

          <Element className={`${styles.head} ${styles.nose_head}`} name='potenza'>ポテンツァ</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img3.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>
            </div>
          </div>

          <Element className={`${styles.head} ${styles.nose_head}`} name='stem-cell'>幹細胞点滴・治療</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img3.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>
            </div>
          </div>

          <Element className={`${styles.head} ${styles.nose_head}`} name='facial-hair-removal'>顔脱毛・美白・シミ取り</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img3.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>
            </div>
          </div>

          <Element className={`${styles.head} ${styles.nose_head}`} name='mole-removal'>ほくろ除去・いぼ除去</Element>
          <div className={styles.content}>
            <div className={styles.flex}>
              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img2.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>

              <div className={styles.item}>
                <div>
                  <img src='/images/injection-area-img3.png' className={styles.item_img} alt='img' />
                </div>
                <div className={styles.title}>バレない黄金比脂肪吸引</div>
                <p>
                  患者様のご希望は、気になるクマを取って涙袋が出るようにしたいとのことでした。
                  クマ取りをすることで涙袋の境目がハッキリとし涙袋が強調されます。
                </p>
              </div>
            </div>
        </div> */}
      </section>

    </>
  )
}

export default Case