import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const EyesEyebrow = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>眉下切開</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>眉下切開とは</h2>
            <p>
                眉毛直下を切開し、余分な皮膚や皮下組織を除去します。<br />
                それにより、目の開きがよくなったり、二重の幅が整います。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-eyes-eyebrow-img.png' alt='about-area-eyes-eyebrow-img' className={styles.pc_img}/>
                <img src='/images/about-area-eyes-eyebrow-sp-img.png' alt='about-area-eyes-eyebrow-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />一重のまままぶたのたるみだけとりたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />はじめて眉下切開をする方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />二重整形をせずに元の二重を生かしたまぶたのたるみをとりたい方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想の眉下をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず目もとの状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望の眉下の距離に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は120分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>眉下切開の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>眉下切開ベーシック</div>
                    <div>¥217,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>眉下切開ロング</div>
                    <div>¥547,800</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※ベーシック：黒目幅</li>
                    <li>※ロング：眉毛幅</li>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：2週間〜2ヶ月<br />
                        ・抜糸(通院)：約1週間後
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・腫れや内出血</li>
                        <li>・傷跡や赤み</li>
                        <li>・糸の跡が残る可能性</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default EyesEyebrow