import React from 'react'
import styles from '../styles/Access.module.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../components/Breadcrumb';

const Access = () => {
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            Access
            <span>アクセス</span>
        </h2>
        <p>
          JR山手線新宿駅から徒歩3分<br />
          ドン・キホーテ新宿ビル7階にあります。
        </p>
      </section>
      <Breadcrumbs />

      <section className={styles.address_area}>
        <div>
          <img src='/images/address-img.png' className={styles.address_img} alt='img' /> 
        </div>
        <table>
          <tbody>
            <tr>
              <td>住所</td>
              <td>
                〒160-0021 東京都新宿区歌舞伎町1-16-5ドン・キホーテ新宿ビル7階<br />
                <Button variant="contained" disableElevation className={styles.access_btn}>
                  <Link to="https://www.google.com/maps/place/T-CLINIC+%E6%96%B0%E5%AE%BF%E9%99%A2/@35.6937755,139.7017873,17z/data=!3m2!4b1!5s0x60188cd9dbc2163f:0x54a22900434443d1!4m6!3m5!1s0x60188df229e9d5eb:0x94f52551d55ea750!8m2!3d35.6937755!4d139.7017873!16s%2Fg%2F11t7zcz795?entry=ttu">
                    <img src='/images/pin-icon.png' className={styles.pin_icon} alt='img' />Google Map
                  </Link>
                </Button>
              </td>
            </tr>
            <tr>
              <td>電車でお越しの方</td>
              <td>JR山手線新宿駅から徒歩3分</td>
            </tr>
            <tr>
              <td>ご予約・お問い合わせ</td>
              <td>
                <span>TEL</span>03-6228-0830<br />
                <span>営業時間：10:00~19:00(不定休)</span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  )
}

export default Access