import React,{ useState} from 'react'
import styles from '../styles/Contact.module.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Breadcrumbs } from '../components/Breadcrumb';

const Contact = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [emailConf, setEmailConf] = useState("");
  const [content, setContent] = useState("");

  //
  const [nameError, setNameError] = useState("");
  const [telError, setTelError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailConfError, setEmailConfError] = useState("");
  const [contentError, setContentError] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();

    let isError = false;

    if (name === "") {
        setNameError("※お名前は必須です");
        isError = true;
    } else {
        setNameError("");
    }

    if (!/^[\d]*$/.test(tel)) {
        setTelError("※正しい電話番号を入力してください");
        isError = true;
    } else {
        setTelError("");
    }

    const emailValidation = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailValidation.test(email)) {
        setEmailError("※正しいメールアドレスを入力してください");
        isError = true;
    } else {
        setEmailError("");
    }

    if (emailConf !== email) {
        setEmailConfError("※メールアドレスと確認用メールアドレスが一致しません");
        isError = true;
    } else {
        setEmailConfError("");
    }

    if (content === "") {
        setContentError("※お問い合わせ内容は必須です");
        isError = true;
    } else {
        setContentError("");
    }

    if (!isError) {
        navigate('/contact/confirm', { state: { name, tel, email, emailConf, content } });
    }
  };
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            Contact
            <span>お問い合わせ</span>
        </h2>
        <p>
          内容確認の上、改めてご連絡いたします。<br />
          お急ぎの方はお電話でご連絡ください。
        </p>
      </section>
      <Breadcrumbs />
      <section className={styles.form_area}>
        <Box
            component="form"
            sx={{
              '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <div className={styles.title}>お名前<span>必須</span></div>
            <TextField
              error={nameError !== ""}
              helperText={nameError}
              id="outlined-basic"
              label="name"
              variant="outlined"
              className={styles.input}
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <div className={styles.title}>電話番号<span className={styles.any}>任意</span> (ハイフンなしで入力してください)</div>
            <TextField
              error={telError !== ""}
              helperText={telError}
              id="outlined-basic"
              label="tel"
              variant="outlined"
              className={styles.input}
              value={tel}
              onChange={e => setTel(e.target.value)}
            />
            <div className={styles.title}>メールアドレス<span>必須</span></div>
            <TextField
              error={emailError !== ""}
              helperText={emailError}
              id="outlined-basic"
              label="mail-address"
              variant="outlined"
              className={styles.input}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <div className={styles.title}>メールアドレス(確認用)<span>必須</span></div>
            <TextField
              error={emailConfError !== ""}
              helperText={emailConfError}
              id="outlined-basic"
              label="mail-address-conf"
              variant="outlined"
              className={styles.input}
              value={emailConf}
              onChange={e => setEmailConf(e.target.value)}
            />
            <div className={styles.title}>お問い合わせ内容<span>必須</span></div>
            <TextField
              error={contentError !== ""}
              helperText={contentError}
              id="outlined-multiline-static"
              label="content"
              multiline
              rows={4}
              className={styles.input}
              value={content}
              onChange={e => setContent(e.target.value)}
            />

            <div className={styles.privacy_text}>
              <Link>プライバシーポリシー</Link>に同意したものとみなされます。
            </div>
            <Button variant="contained" className={styles.submit_button} type="submit">
              確認する
            </Button>
        </Box>
          
          
      </section>
    </>
  )
}

export default Contact