import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const Potenza = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>ポテンツァ</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>ポテンツァとは</h2>
            <p>
                マイクロニードルで肌表面に細かい穴を開け、肌に薬剤を浸透させ、同時に熱を与えることでダウンタイムが少ない施術です。<br />
                創傷治癒の過程でコラーゲンやエラスチンの生成を促し、また薬剤による相乗効果があります。<br />
                T CLINICでは医師が直接ポテンツァを年間1000名以上施術を行い、効果を最大化するための独自のマニュアルを作成しました。<br />
                なのでメーカー推奨のマニュアルでは治療が困難だったニキビ・ニキビ跡・毛穴に対しても効果を実感できます。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-potenza-img.png' alt='about-area-potenza-img' className={styles.pc_img}/>
                <img src='/images/about-area-potenza-sp-img.png' alt='about-area-potenza-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />肌質改善したい
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />ニキビ跡を直したい
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />肌の張りを取り戻したい
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず肌の状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は30分程度になります。<br />
                            ※施術中は局所麻酔を使用致しますので痛みはございません。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>ポテンツァの症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>ポテンツァ</div>
                    <div>¥41,800〜¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>肝斑コース</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>水光肌コース両頬</div>
                    <div>¥41,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>水光肌コース全顔</div>
                    <div>¥74,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ドラッグデリバリー全顔</div>
                    <div>¥107,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>DIAMONDフェイスライン</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>DIAMOND全顔</div>
                    <div>¥85,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>表面麻酔</div>
                    <div>¥11,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>にきび1箇所(5箇所から)</div>
                    <div>¥11,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>顎下追加</div>
                    <div>¥22,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>首追加</div>
                    <div>¥22,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>肝斑治療</div>
                    <div>¥22,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>ヒアルロン酸</div>
                    <div>¥11,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>McCoom</div>
                    <div>¥33,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>幹細胞エクソソーム</div>
                    <div>¥66,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>トラネキサム酸500mg60錠</div>
                    <div>¥4,400</div>
                </div>
                <div className={styles.price_list}>
                    <div>シナール配合錠60錠</div>
                    <div>¥3,300</div>
                </div>
                <div className={styles.price_list}>
                    <div>トラネキサム酸500mg60錠(処方のみ)</div>
                    <div>¥7,700</div>
                </div>
                <div className={styles.price_list}>
                    <div>シナール配合錠60錠(処方のみ)</div>
                    <div>¥5,500</div>
                </div>
                <div className={styles.price_list}>
                    <div>マヌカピール初回</div>
                    <div>¥9,900</div>
                </div>
                <div className={styles.price_list}>
                    <div>マヌカピール1回</div>
                    <div>¥15,730</div>
                </div>
                <div className={styles.price_list}>
                    <div>マヌカピール5回</div>
                    <div>¥49,500</div>
                </div>
                <div className={styles.price_list}>
                    <div>ハイドラジェントル1回(ポテンツァ併用)</div>
                    <div>¥11,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>ハイドラジェントル1回</div>
                    <div>¥16,500</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間(メンテナンス)：1ヶ月〜8ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・内出血</li>
                        <li>・赤み</li>
                        <li>・むくみ</li>
                        <li>・熱感</li>
                        <li>・色素沈着</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default Potenza