import React, { useState } from 'react';
import styles from '../styles/Header.module.css';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import BurgerMenu from './BurgerMenu';

const Header = () => {
  const [showComponent, setShowComponent] = useState(false);

  const handleClick = () => {
    setShowComponent(!showComponent);
  };

  return (
    <header>
        <div className={styles.header_contact}>
            <div>
                <span>美容外科/美容皮膚科 JR山手線新宿駅から徒歩3分</span>
                <Link className={styles.logo_area} to="/">
                    <img src='/images/logo.png' className={styles.logo} alt='img' />
                    <h1>
                        T CLINIC<br />
                        美容外科/美容皮膚科
                    </h1>
                </Link>
            </div>
            <div className={styles.header_cta}>
                <Button variant="contained" className={styles.line_button}>
                    <Link to="https://page.line.me/862rmedt">
                        <img src='/images/line-icon.png' className={styles.line_button_icon} alt='img' />LINE相談・予約
                    </Link>
                </Button>
                <Button variant="contained" className={styles.web_button}>
                    <Link to="https://connect.kireipass.jp/clinics/t-clinic-shinjuku/reservations/new?large_category_id=6185&menu_id=19150">WEB予約</Link>
                </Button>
                <Button variant="contained" className={styles.tel_button}>
                    <Link to="tel:03-6228-0830">
                        <div>
                            <span className={styles.time}>受付時間: 10:00~19:00(不定休)</span><br />
                            TEL <span className={styles.num}>03-6228-0830</span>
                        </div>
                    </Link>
                </Button>
                <img src='/images/ham.png' className={styles.hum_img} alt='img' onClick={handleClick} />
                <div className={`${styles.burgerMenu} ${showComponent ? styles.show : ''}`}>
                    {showComponent && <BurgerMenu onClose={handleClick} />}
                </div>
            </div>
        </div>
        <nav className={styles.header_nav}>
            <ul>
                <li><Link to="/">ホーム</Link></li>
                <li><Link to="/about">当院について</Link></li>
                <li><Link to="/menu">施術メニュー・料金</Link></li>
                <li><Link to="/case">症例写真</Link></li>
                <li><Link to="/doctor">ドクター紹介</Link></li>
                <li><Link to="/access">アクセス</Link></li>
                <li><Link to="/contact">お問い合わせ</Link></li>
            </ul>
        </nav>
    </header>
  );
};

export default Header;