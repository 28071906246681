import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const Cellnatant = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>エクソソーム点滴</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                {/* <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li> */}
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>エクソソーム点滴とは</h2>
            <p>
                エクソソームとは、体内に存在する幹細胞を培養し、その培養液からエクソソームを採取して滅菌などの処理を行ったもののことをいいます。<br />
                体内に投与することで、怪我や病気、加齢などで損傷した細胞を修復し、組織機能を回復させます。お手軽に若返り効果を実感できます。<br />
                T CLINICでは100社以上から厳選し、脂肪・臍帯由来のエクソソーム製剤を導入しております。<br />
                不純物がほとんど入っていないので、安全に副作用なく使用できます。また濃度がかなり高く、効果を実感しやすいです。<br />
                T CLINICでは「慢性疼痛に対する自家脂肪由来幹細胞による治療」に対し、「日本肌再生医学会特定認定再生医療等委員会」にて第二種 再生医療等提供計画（治療）を国に届出しております。
            </p>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />なんとなく疲れている
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />治したい病気がある
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />元気になりたい
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は45-60分程度になります。<br />
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>高濃度美容幹細胞培養上清点滴の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>エクソソーム点滴</div>
                    <div>¥96,800〜¥1,605,120</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームシングル1回</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームシングル4回(10%オフ)</div>
                    <div>¥348,480</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームシングル8回(20%オフ)</div>
                    <div>¥619,520</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームダブル1回</div>
                    <div>¥173,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームダブル4回(10%オフ)</div>
                    <div>¥625,680</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームダブル8回(20%オフ)</div>
                    <div>¥1,112,320</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームトリプル1回</div>
                    <div>¥250,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームトリプル4回</div>
                    <div>¥902,880</div>
                </div>
                <div className={styles.price_list}>
                    <div>エクソソームトリプル8回</div>
                    <div>¥1,605,120</div>
                </div>
                <div className={styles.price_list}>
                    <div>*高濃度ビタミンC点滴25g</div>
                    <div>¥22,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>*白玉点滴1,200mg</div>
                    <div>¥11,000</div>
                </div>
                <div className={styles.price_list}>
                    <div>*αリポ酸点滴300mg</div>
                    <div>¥11,000</div>
                </div>
                <ul className={styles.annotation}>
                    <li>
                        ※濃度ビタミンC点滴：高濃度ビタミンC点滴では、最大で1回でレモン2500個分のビタミンCを補給できます。サプリメントや内服薬よりも身体への吸収効率が高く、高濃度の成分を身体にダイレクトに注入するため、比較的短期間で効果を実感して頂けるおすすめの施術です。
                    </li>
                    <li>
                        ※白玉点滴：主成分であるグルタチオンにより、美白・エイジングケアに加え、疲労回復、解毒作用、⼆⽇酔い改善、肝機能改善が期待されます。グルタチオンは3つのアミノ酸からなる物質で元々筋⾁や肝臓などに分布し、強力な抗酸化作用を⽰す成分です。紫外線や加齢に伴う活性酸素の増加、老化を防⽌、メラニン色素生成の抑制など、美しく透明感のある肌をキープします。
                    </li>
                    <li>
                        ※αリポ酸点滴：老化の原因である体が焦げる現象、いわゆる「糖化」対策に、αリポ酸が効果を発揮することが注目されています。αリポ酸は細胞内にある「ミトコンドリア」内でエネルギー合成を担う補酵素として働いています。体の中に入ってきた「糖」や「脂肪酸」を消費してエネルギーを産生させます。
                    </li>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間(メンテナンス)：1週間〜5ヶ月<br />
                        ・通院：1回
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・点滴による軽度の痛み</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default Cellnatant