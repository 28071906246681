import React from 'react'
import styles from '../../styles/Complete.module.css'
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Breadcrumbs } from '../../components/Breadcrumb';

const Complete = () => {
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            Contact
            <span>お問い合わせ</span>
        </h2>
        <p>
          内容確認の上、改めてご連絡いたします。<br />
          お急ぎの方はお電話でご連絡ください。
        </p>
      </section>
      <Breadcrumbs />
      <section className={styles.form_area}>
        <div>送信が完了しました。</div>
        <p>
            3日以内に担当者よりご連絡いたします。<br />
            今しばらくお待ちください。
        </p>
        <Button variant="contained" className={styles.submit_button}>
            <Link to="/">トップページへ</Link>
        </Button>
      </section>
    </>
  )
}

export default Complete