import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const FacesBotox = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>エラボトックス</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>エラボトックスとは</h2>
            <p>
                筋肉の神経伝達を遮断するボトックスを咬筋にうち、エラのハリを抑え小顔にしたり、 歯ぎしりの改善などに効果的です。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-faces-botox-img.png' alt='about-area-faces-botox-img' className={styles.pc_img}/>
                <img src='/images/about-area-faces-botox-sp-img.png' alt='about-area-faces-botox-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />小顔にしたい方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />エラのハリが気になる方
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />歯ぎしりが気になる方
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想のフェイスラインをお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまずフェイスラインの状態を確認し、患者様にあったを施術ご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望のフェイスラインに添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は10分程度になります。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>エラボトックスの症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>ボツラックスエラ通常</div>
                    <div>¥41,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ボツラックスエラ強力</div>
                    <div>¥63,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガンエラ通常</div>
                    <div>¥63,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>アラガンエラ強力</div>
                    <div>¥85,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>施術料（笑気麻酔込み）</div>
                    <div>¥22,000</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：1ヶ月<br />
                        ・通院：必要なし
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・効果が現れるまでに時間がかかる</li>
                        <li>・頭痛、内出血</li>
                        <li>・表情がこわばる可能性がある</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default FacesBotox