import React from 'react'
import styles from '../styles/NotFound.module.css';
import { Breadcrumbs } from '../components/Breadcrumb';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            404 Not Found
            <span>お探しのページは見つかりませんでした。</span>
        </h2>
      </section>
      <Breadcrumbs />
      <section className={styles.content}>
        <p>
            申し訳ございません。<br />
            お探しのページは削除されたか、URLが変更された可能性がございます。
        </p>
        <Button variant="contained" className={styles.button}>
          <Link to="/">TOP</Link>
        </Button>
      </section>
    </>
  )
}

export default NotFound