import React, { useEffect, useState } from 'react';
import styles from '../../styles/Confirm.module.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Breadcrumbs } from '../../components/Breadcrumb';

const Confirm = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get location from the useLocation hook
  const [loading, setLoading] = useState(false); // ローディング状態のstate
  const { name = "", tel = "", email = "", emailConf = "", content = "" } = location.state || {};
  const [isSubmitted, setIsSubmitted] = useState(false); // 送信されたかどうかの状態

  useEffect(() => {
    // ページがロードされたときに送信状態をチェックし、送信済みならリダイレクト
    if (isSubmitted) {
      navigate('/contact');
    }
  }, [isSubmitted, navigate]);

  const sendData = () => {
    if (isSubmitted) {
      // すでに送信されている場合は何もしない
      return;
    }
    setLoading(true);
    const data = {
      name,
      tel,
      email,
      emailConf,
      content
    };

    axios.post('https://tclinic-official.com/mail/index.php', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      setLoading(false);
      setIsSubmitted(true); // 送信状態をtrueに設定
      navigate('/contact/complete', { replace: true }); // 'replace'オプションで履歴を置き換え
    })
    .catch(error => {
      console.log(error);
      setLoading(false);
    });
  };

  return (
    <>
      <section className={styles.fv_area}>
        <h2>
            Contact
            <span>お問い合わせ</span>
        </h2>
        <p>
          内容確認の上、改めてご連絡いたします。<br />
          お急ぎの方はお電話でご連絡ください。
        </p>
      </section>
      <Breadcrumbs />
      <section className={styles.form_area}>
        <form>
          <div className={styles.title}>お名前<span>必須</span></div>
          <div className={styles.input_text}>{name}</div>

          <div className={styles.title}>電話番号<span className={styles.any}>任意</span></div>
          <div className={styles.input_text}>{tel}</div>

          <div className={styles.title}>メールアドレス<span>必須</span></div>
          <div className={styles.input_text}>{email}</div>

          <div className={styles.title}>メールアドレス(確認用)<span>必須</span></div>
          <div className={styles.input_text}>{emailConf}</div>

          <div className={styles.title}>お問い合わせ内容<span>必須</span></div>
          <div className={styles.input_text_area}>{content}</div>

          <div className={styles.privacy_text}>
            <Link>プライバシーポリシー</Link>に同意したものとみなされます。
          </div>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button variant="contained" className={styles.submit_button} onClick={sendData}>
              送信する
            </Button>
          )}
          <div className={styles.back_text}>
            <Link to="/contact">戻って編集する</Link>
          </div>
        </form>
      </section>
    </>
  )
}

export default Confirm