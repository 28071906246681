import React from 'react'
import styles from '../../styles/MenuDetail.module.css'
import { Link as ScrollLink, Element } from 'react-scroll';
import { Breadcrumbs } from '../../components/Breadcrumb'

const EyesHyaluronic = () => {
  return (
    <>
        <section className={styles.fv_area}>
            <h2>ヒアルロン酸注射(涙袋)</h2>
        </section>
        <Breadcrumbs />

        <section className={styles.jump_link_area}>
            <ul>
                <li>
                    <ScrollLink to="target" smooth={true}>おすすめの方</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="flow" smooth={true}>施術の流れ</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="case" smooth={true}>症例写真</ScrollLink>
                </li>
                <li>
                    <ScrollLink to="price" smooth={true}>料金</ScrollLink>
                </li>
            </ul>
        </section>

        <Element className={styles.about_area} name='target'>
            <h2>ヒアルロン酸注射(涙袋)とは</h2>
            <p>
                涙袋とは、下まぶたの縁に沿った膨らみのことを差します。<br />
                涙袋がある人と無い人との違いは、眼輪筋という筋肉が発達している人と、発達していない人との違いですが、<br />
                涙袋がない人もヒアルロン酸を注入することによって、目元の印象が愛らしく優しい印象になります。
            </p>
            <div className={styles.img_wrapper}>
                <img src='/images/about-area-eyes-hyaluronic-img.png' alt='about-area-eyes-hyaluronic-img' className={styles.pc_img}/>
                <img src='/images/about-area-eyes-hyaluronic-sp-img.png' alt='about-area-eyes-hyaluronic-sp-img' className={styles.sp_img}/>
            </div>
            <div className={styles.target}>
                <h3>おすすめの方</h3>
                <ul>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />ふっくらとした涙袋がほしい
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />目もとを大きく見せたい
                    </li>
                    <li>
                        <img src='/images/check-icon.png' className={styles.check_icon} alt='img' />手軽にかわいい目もとを作りたい
                    </li>
                </ul>
            </div>
        </Element>
        <Element className={styles.flow_area} name='flow'>
            <h2>施術の流れ</h2>
            <div className={styles.cards}>
                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>カウンセリングで理想の涙袋状態をお伺いいたします。</div>
                        <div className={styles.text}>
                            無料カウンセリングではまず目もとの状態を確認し、患者様にあった施術をご提案いたします。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img2.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>ご希望の涙袋に添って施術を行います。</div>
                        <div className={styles.text}>
                            空きがあればカウンセリング当日の施術も可能です。施術時間は10-15分程度になります。
                        </div>
                    </div>
                </div>

                <div className={styles.card}>
                    <div>
                        <img src='/images/flow-img3.png' className={styles.card_img} alt='img' />
                    </div>
                    <div>
                        <div className={styles.title}>施術後はそのままお帰りいただけます。</div>
                        <div className={styles.text}>
                            施術によってはパウダールームにてお化粧しご帰宅可能です。特に通院の必要はございませんが、気になることなどございましたら、クリニックにお気軽にご連絡ください。
                        </div>
                    </div>
                </div>

            </div>
        </Element>
        {/* <Element className={styles.case_area} name='case'>
            <h2>ヒアルロン酸注射の症例写真</h2>
            <div className={styles.items}>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img2.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src='/images/case-area-img3.png' className={styles.case_img} alt='img' />
                    </div>
                </div>
            </div>
        </Element> */}

        <Element className={styles.price_area} name='price'>
            <h2>料金</h2>
            <div className={styles.price_lists}>
                <div className={styles.price_list}>
                    <div>Neuramis Lidocaine 1cc</div>
                    <div>¥52,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>ジュビダームVOLBELLA XC 1cc</div>
                    <div>¥96,800</div>
                </div>
                <div className={styles.price_list}>
                    <div>施術料（笑気麻酔込み）</div>
                    <div>¥22,000</div>
                </div>
                <ul className={styles.annotation}>
                    <li>※料金は全て「税込」記載となります</li>
                </ul>
            </div>
            <div className={styles.supplement}>
                <div>
                    <h3>治療期間・回数</h3>
                    <p>
                        ・治療期間：2週間~1ヶ月<br />
                        ・通院：必要なし
                    </p>
                </div>
                <div>
                    <h3>リスク・副作用</h3>
                    <ul>
                        <li>・持続期間には個人差があり</li>
                        <li>・左右差(元の骨格により異なる)</li>
                    </ul>
                </div>
            </div>
        </Element>
    </>
  )
}

export default EyesHyaluronic